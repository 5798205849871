import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Avatar, Tooltip } from "antd";
import ReactDOMServer from "react-dom/server";

const SalesPerformanceChart = ({ chart }) => {
  const transformData = (data) => {
    const categories = data.map((person) => person.fullname);
    const seriesNames = [
      ...new Set(
        data.flatMap((person) => person.result.map((item) => item.name))
      ),
    ];
    const colors = [
      ...new Set(
        data.flatMap((person) => person.result.map((item) => item.color))
      ),
    ];

    const series = seriesNames.map((name) => ({
      name: name,
      data: categories.map((category) => {
        const person = data.find((p) => p.fullname === category);
        const result = person?.result.find((r) => r.name === name);
        return result ? parseInt(result.count) : 0;
      }),
    }));

    return { categories, series, colors };
  };

  const getInitials = (name) => {
    const names = String(name).split(" ");
    const initialsValue = names.map((n) => n[0].toUpperCase()).join("");

    return initialsValue;
  };

  const { categories, series, colors } = transformData(chart);
  const barHeight = 40;
  const padding = 10;
  const chartHeight = series.length * barHeight + padding;

  const chartOptions = {
    series: series,
    options: {
      chart: {
        height: chartHeight,
        type: "bar",
        stacked: true,
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          barHeight: barHeight, // Set the height of each bar

          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 20,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      colors: colors, // Colors for the series
      dataLabels: {
        enabled: true,
      },
      // stroke: {
      //   curve: "straight",
      //   width: [3, 3],
      // },
      title: {
        text: "",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: categories,
        labels: {
          formatter: function (value) {
            return value?.toLocaleString(); // Use toLocaleString to add thousand separators
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (value, index) {
            return value;
          },
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartOptions.options}
          series={chartOptions.series}
          type="bar"
          height={375}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default SalesPerformanceChart;
