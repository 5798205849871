// CustomModal.js
import React from "react";
import { Modal, Button } from "antd";

class CustomModal extends React.Component {
  render() {
    const {
      visible,
      title,
      onCancel,
      onOk,
      children,
      recalculate,
      footerBtn,
      heading,
      titleClass,
    } = this.props;

    return (
      <Modal
        open={visible}
        destroyOnClose
        className="small-modal"
        title={title}
        onCancel={onCancel}
        onOk={onOk}
        footer={[
          <Button key="back" onClick={recalculate}>
            {footerBtn}
          </Button>,
          <Button key="submit" type="primary" onClick={onOk}>
            Ok
          </Button>,
        ]}
      >
        <div className="form-wrapper">
          <div className={titleClass}>
            <h3>{heading}</h3>
          </div>
          {children}
        </div>
      </Modal>
    );
  }
}

export default CustomModal;
