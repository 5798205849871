export const RoleController = {
  ceo: {
    view: ["/project"],
    edit: [
      "/employees",
      "/finance/expenses",
      "/finance/income",
      "/finance/support",
    ],
  },
  pm: {
    view: ["/project"],

    edit: ["/projects"],
  },
  hr: {
    view: ["/project"],

    edit: ["/employees"],
  },
};
