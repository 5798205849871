import { useQuery, useMutation, useQueryClient } from "react-query";
// import { useSelector } from "react-redux";
function useUniversalFetch() {
   const queryClient = useQueryClient();
//   const { currentLanguage: lang } = useSelector((state) => state?.language);


   const fetchData = async ({
      url,
      method = "GET",
      token,
      body = null,
      headers = {},
   }) => {
      
      const isFormData = body instanceof FormData
      const fetchHeaders = {
         ...headers,
         Authorization: token ? `Bearer ${token}` : undefined,
     };
 
     // Only set the Content-Type to application/json if the body is not FormData
     if (!isFormData) {
         fetchHeaders["Content-Type"] = "application/json";
     }
      const response = await fetch(url, {
         method: method,

         headers: fetchHeaders,
         body: (!isFormData && (method !== "GET" && method !== "DELETE")) ? JSON.stringify(body) : body,
      
      });

      if (!response.ok) {
        const res = await response.json()

   const error = new Error(
      `${
         res?.message?.message || res?.message
      }`,
   );
   error.status = response.status; // HTTP status kodini xato obyektiga qo'shamiz
   throw error;

   
}


      return response.json();
   };

   const useFetchQuery = ({ queryKey, url, id, token=false, config = {} }) => {
      const queryKeyPrefix = [queryKey, id];
      let url2 = id ? `${url}${id}` : url;
  
   
   return   useQuery(queryKeyPrefix, () => fetchData({ url:url2, token, ...config }), {
         // react-query options
         keepPreviousData: true,
         ...config.queryOptions,
      });
   }
   const useFetchMutation = ({ url, method,  token=false, config = {} }) =>
      useMutation((data) => fetchData({ url, method,  token,  body: data, ...config }), {
         onSuccess: () => {
            queryClient.invalidateQueries(); // Optionally invalidate queries after mutation
         },
         ...config.mutationOptions,
      });

      const usePatchMutation = ({ url, token = false, config = {} }) =>
      useMutation(
        ({ id, data }) => fetchData({ url: `${url}/${id}`, method: "PATCH", token, body: data }),
        {
          onSuccess: () => {
            queryClient.invalidateQueries(); // Optionally invalidate queries after mutation
          },
          ...config.mutationOptions,
        }
      );
  
      const useDeleteMutation = ({ url, token = false, config = {} }) =>
      useMutation(
        ({ id }) => fetchData({ url: `${url}/${id}`, method: "DELETE", token }),
        {
          onSuccess: () => {
            queryClient.invalidateQueries(); // Optionally invalidate queries after mutation
          },
          ...config.mutationOptions,
        }
      );
   return { useFetchQuery, useFetchMutation, usePatchMutation, useDeleteMutation };
}

export default useUniversalFetch;
