import React, { useState, useEffect } from "react";
import {
  Input,
  DatePicker,
  Select,
  Button,
  Upload,
  Modal,
  Form,
  message,
} from "antd";
// import { CheckOutlined } from '@ant-design/icons';
import { useParams } from "react-router";
import Icon from "../../../../../Components/Icon";
import "react-phone-number-input/style.css";
import { BASE_URL } from "../../../../../Consts/variables";
import useUniversalFetch from "../../../../../Hooks/useApi";
import moment from "moment";
import dayjs from "dayjs";
// import PhoneInput from "react-phone-number-input";
import { UploadOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const KanbanModal = ({
  expense,
  isVisible,
  onSave,
  onCancel,
  isEditMode,
  isLoading,
  isSuccess,
}) => {
  const [form] = Form.useForm();
  const [canban_status, setCanban_status] = useState("");
  const { playgroundId } = useParams();
  const token =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("authToken"));
  const { useFetchQuery, useFetchMutation, usePatchMutation } =
    useUniversalFetch();

  const {
    data: salesData,
    isLoading: isSalesDataLoading,
    error: salesDataError,
    isError: isSalesDataError,
    isSuccess: isSuccessalesData,
  } = useFetchQuery({
    queryKey: "creators",
    token: token,
    url: `${BASE_URL}playground/${playgroundId}`,
  });

  const sales = salesData?.data ? salesData?.data : [];
  const [fileList, setFileList] = useState(expense?.files || []);

  useEffect(() => {
    setCanban_status(isVisible);
    if (expense?.files?.length) {
      setFileList(
        expense?.files?.map((file) => ({
          uid: file?.id,
          name: file?.file_name,
          status: "done",
          url: `${BASE_URL}${file?.mime_type}/${file?.path}`,
        }))
      );
    } else {
      setFileList([]);
    }

    form.setFieldsValue(
      expense
        ? {
            project_name: expense?.name,
            date: dayjs(
              String(moment(Number(expense?.deadline)).format("DD/MM/YYYY")),
              dateFormat
            ),
            priority: expense?.priority || "medium",
            creators: expense?.employees?.map((employee) => employee.id),
            files: expense?.files,
            description: expense?.text_editor,
          }
        : {
            project_name: null,
            date: null,
            priority: null,
            creators: null,
            description: null,
            files: [],
          }
    );
  }, [expense, form, isVisible]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const other = {
          canban_status,
          expense: expense,
          files: fileList?.map((file) => file.uid),
        };

        onSave(values, isEditMode, other);
        form.resetFields();

        // onCancel();
      })
      .catch((error) => {
        console.error("Submission failed:", error?.message);
      });
  };

  const dateFormat = "DD/MM/YYYY";

  const modalHeader = (
    <div>
      <h1>{isEditMode ? "Edit project" : "Add new project"}</h1>
      <p>
        {isEditMode ? "Here you may edit." : "Here you may add new canban."}
      </p>
    </div>
  );

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleRemove = async (file) => {
    try {
      const response = await fetch(`${BASE_URL}file/${file.uid}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        setFileList((prevList) =>
          prevList.filter((item) => item.uid !== file.uid)
        );
        message.success(`${file.name} deleted successfully.`);
      } else {
        throw new Error("Delete failed");
      }
    } catch (error) {
      message.error(`Failed to delete ${file.name}.`);
    }
  };

  const customUpload = ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_name", file.name);

    fetch(`${BASE_URL}file`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        onSuccess(result);
        console.log(result.data);
        setFileList((prevList) => [
          ...prevList,
          {
            uid: result.data?.id,
            name: result.data?.file_name,
            status: "done",
            url: `${BASE_URL}${result.data?.mime_type}/${result.data?.path}`, // Assuming your API returns the URL of the uploaded file
          },
        ]);
        message.success(`${file.name} file uploaded successfully.`);
      })
      .catch((error) => {
        onError(error);
        message.error(`${file.name} file upload failed.`);
      });
  };

  const disablePastDates = (current) => {
    // Disable dates before today
    return current && current < moment().startOf("day");
  };

  return (
    <Modal
      open={isVisible}
      onCancel={handleCancel}
      destroyOnClose
      className="expenses-modal"
      width={990}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={handleSubmit}
        >
          {isEditMode ? "Update" : "Save"}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <div className="form-wrapper" style={{ width: "900px" }}>
          <div className="title">{modalHeader}</div>
          <div className="input-wrapper expenses-input-wrapper">
            <div className="single-input-item">
              <Form.Item
                name="project_name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please fill the input",
                    // validateTrigger: 'onChange'
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Input placeholder="Write name..." />
              </Form.Item>
            </div>

            <div className="single-input-item calendar">
              <Form.Item
                name="date"
                label="Deadline"
                rules={[
                  {
                    required: true,
                    message: "Please input deadline!",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <DatePicker
                  disabledDate={disablePastDates}
                  placeholder="DD/MM/YYYY"
                  format={dateFormat}
                  onChange={(dateString) => {
                    form.setFieldsValue({ date: dateString });
                  }}
                  defaultValue={expense?.date ? moment(expense.date) : null}
                />
              </Form.Item>
            </div>

            <div className="single-input-item">
              <Form.Item
                name="priority"
                label="Priority"
                rules={[
                  {
                    required: true,
                    message: "Select agent...",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Select placeholder="Select agent...">
                  {/* {sales &&
                    sales.map((member, index) => (
                      <Select.Option key={index} value={member?.id}>
                        {member.fullname}{" "}
                        {member.role ? <strong>({member?.role})</strong> : null}
                      </Select.Option>
                    ))} */}
                  <Select.Option key={1} value={"high"}>
                    High
                  </Select.Option>
                  <Select.Option key={2} value={"medium"}>
                    Medium
                  </Select.Option>
                  <Select.Option key={3} value={"low"}>
                    Low
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className="single-input-item multiple_select_trello">
            <Form.Item name="creators" label="Add creators">
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                filterOption={(input, option) => {
                  const member = sales?.employees?.find(
                    (m) => m.id === option.value
                  );
                  const fullNameMatch = member?.fullname
                    ?.toLowerCase()
                    .includes(input.toLowerCase());
                  const jobTitleMatch = member?.job_title?.name
                    ?.toLowerCase()
                    .includes(input.toLowerCase());
                  return fullNameMatch || jobTitleMatch;
                }}
              >
                {sales?.employees?.map((member, index) => (
                  <Select.Option key={index} value={member.id}>
                    {member.fullname}{" "}
                    {member.job_title ? (
                      <strong>({member.job_title.name})</strong>
                    ) : null}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="textarea_input">
            <div className="single-input-item">
              <Form.Item
                name="description"
                label="Description"
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <TextArea
                  placeholder="Controlled autosize"
                  autoSize={{
                    minRows: 3,
                    maxRows: 5,
                  }}
                />
              </Form.Item>
            </div>
          </div>

          <div className="file_upload_trello">
            <Upload
              fileList={fileList}
              onRemove={handleRemove}
              customRequest={customUpload}
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default KanbanModal;
