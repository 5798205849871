import React, { useState, useEffect } from 'react';
import { Table, Button, Switch, Popover, notification, Spin } from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import CountryModal from './Action';  
import Icon from "../../../../Components/Icon";
import { BASE_URL } from '../../../../Consts/variables';
import useUniversalFetch from '../../../../Hooks/useApi';

function Country() {
  const [modalVisible, setModalVisible] = useState(false);
  const [editValues, setEditValues] = useState(null);
  const [activeTitle, setActiveTitle] = useState(null)
  const [opacity, setOpacity] = useState("0")
  const [loader, setLoader] = useState("1")
  const [isActive, setIsActive] = useState("")


  const token = typeof window !== "undefined" && JSON.parse(localStorage.getItem('authToken'));
  const {useFetchQuery, useFetchMutation, usePatchMutation} = useUniversalFetch()

  const {
    data: countryData,
    isLoading: isCountryLoading,
    error: countryError,
    isError: isCountryError,
    isSuccess: isSuccesCountry
  } = useFetchQuery({
      queryKey: "country",
      token: token,
      url: `${BASE_URL}setting/region`,
      id:`${isActive ? `?is_active=${isActive}` : ""}`
      
  });


  useEffect(() => {
    if ((!isCountryLoading && countryData && isSuccesCountry)) {
        setLoader("0");
        setOpacity("1");
    }else{
      setLoader("1");
      setOpacity("0");
    }

  }, [isCountryLoading, countryData]);

  const data = countryData?.status === 200 ? countryData.data : null;

  const { data: countryCreateData, isSuccess:isSuccessCreated, mutate: countryCreate, isLoading: isCountryCreateLoading, error: countryCreateError, isError: isCountryCreateError } = useFetchMutation
  ({
    url: `${BASE_URL}setting/region`,
    method: 'POST',
    token: token,
});

const { data: countryUpdateData, isSuccess:isSuccessUpdated, mutate: countryUpdate, isLoading: isCountryUpdateLoading, error: countryUpdateError, isError: isCountryUpdateError } = usePatchMutation({
  url: `${BASE_URL}setting/region`,
  method: 'PATCH',
  token: token,
});


useEffect(()=> {
  if (isSuccessCreated) {
      notification.success({
      // message: 'Project created',
      message: countryCreateData?.message
    });
  }
 else if (isCountryCreateError) {
    notification.error({
      message: 'Country not created',
      description: countryCreateError?.message
    });
  }
}, [isCountryCreateError, countryCreateData, countryCreateError])


useEffect(()=> {
  if (isSuccessUpdated) {
      notification.success({
      // message: 'Project created',
      message: countryUpdateData?.message
    });
  }
 else if (isCountryUpdateError) {
    notification.error({
      message: 'Country not Updated',
      description: countryUpdateError?.message
    });
  }
}, [isCountryUpdateError, countryUpdateData, countryUpdateError])


  const [open, setOpen] = useState(false);
  const handleMenuClick = (e) => {
    if (e.key === '3') {
      setOpen(false);
    }
  };
  const handleOpenChange = (nextOpen, info) => {
    if (info.source === 'trigger' || nextOpen) {
      setOpen(nextOpen);
    }
  };

  const handleChange = (value) => {
    if (isActive !== "" && isActive === value) {
      setIsActive("")
    }else{
      setIsActive(value);
    }
  };
  

  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
    },
    {
      title: (
       <div className="status">
         <Popover placement="bottom" content={
          <div className='pop-over'>
              <label className="filter-checkbox">
                  <input
                      type="checkbox"
                      checked={isActive === "true"}
                      onChange={() => handleChange("true")}
                  />
                  <span className="checkmark"></span>
                  <span>Active</span>
              </label>
              <label className="filter-checkbox">
                  <input
                      type="checkbox"
                      checked={isActive === "false"}
                      onChange={() => handleChange("false")}
                  />
                  <span className="checkmark"></span>
                  <span>Not active</span>
              </label>
          </div>
          }>
            <span>Status</span>   
            <Icon icon="arrow-down"/>
          </Popover>
       </div>
      ),
      dataIndex: 'status',
      render: (status, record) => (
        <div className="switch">
          <Switch checked={record.is_active} onClick={() => {
            handleSwitchChange(record, !record.is_active); }} />
        </div>
      ),
      width: 300,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => (
        <span className='edit'>
          <Icon onClick={() => {
            handleEdit(record);
            setActiveTitle(record);
          }}
           icon="edit"/>
        </span>
      ),
      
    },
  ];


  const handleAdd = () => {
    setEditValues(null);
    setModalVisible(true);
  };

  const handleEdit = (record) => {
    setEditValues(record);
    setModalVisible(true);
  };

  const handleModalOk = (values, key) => {
    if(!editValues){
      countryCreate({
        "name": values.title,
  
      })
    }
    

    if(editValues){
      countryUpdate({
        id: editValues.id,
        data:{
          "is_active": values.status,
          "name": values.title
        }
      })
    }

    setModalVisible(false);  
  };

  const handleModalCancel = () => {
    setModalVisible(false);  
  };


  const handleSwitchChange = (key, newStatus) => {
    // setActiveTitle(key)

    countryUpdate({
      id:key.id,
      data:{
        "is_active": newStatus,

      }
    })
  };

  

  return (
    <>
    <div className="settings-table" style={{ opacity: opacity }}>
      <Table columns={columns} dataSource={data} size="middle" pagination={false} />
      <div className="bottom-side">
        <Button size='large' type="primary" icon={<PlusOutlined />} onClick={handleAdd}>
          Add Country
        </Button>
      </div>
      <CountryModal
        visible={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        initialValues={editValues}
        />
    </div>
    <div className="loader" style={{ opacity: loader }}>
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 44,
            }}
            spin
          />
        }
      />
    </div>
    </>
  );
}

export default Country;
