import React, { useEffect } from "react";
import { Modal, Button, Switch, Input, Form } from "antd";

function CountryModal({ visible, onOk, onCancel, initialValues }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        title: initialValues ? initialValues.name : "",
        status: initialValues ? initialValues.is_active : true,
      });
    }
  }, [visible, initialValues, form]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onOk(values, initialValues?.key);
      })
      .catch((info) => {
        console.log("Validate Failed:");
      });
  };

  const modalHeader = (
    <div className="title">
      <h1>{initialValues ? "Edit Experiance" : "Add Experiance"}</h1>
    </div>
  );

  return (
    <Modal
      open={visible}
      destroyOnClose
      onOk={handleSubmit}
      className="small-modal"
      onCancel={() => {
        form.resetFields(); // Reset the form on cancel
        onCancel(); // Assume onCancel handles closing the modal
      }}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Ok
        </Button>,
      ]}
    >
      <div className="form-wrapper">
        {modalHeader}
        <Form form={form} layout="vertical" name="countryForm">
          <div className="single-item">
            <p>Experiance</p>
            <Form.Item
              name="title"
              rules={[
                { required: true, message: "Please input the experiance!" },
              ]}
            >
              <Input placeholder="Experiance" />
            </Form.Item>
          </div>
          {initialValues && (
            <div className="single-item">
              <p>Status</p>
              <Form.Item name="status" valuePropName="checked">
                <Switch checked={true} />
              </Form.Item>
            </div>
          )}
        </Form>
      </div>
    </Modal>
  );
}

export default CountryModal;
