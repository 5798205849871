import React, { useState, useEffect } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import Logo from "../../Assets/images/header-logo.svg";
import Line from "../../Assets/images/back-line.svg";
import Icon from "../../Components/Icon";
// import { Router } from 'react-router';
import { useNavigate } from "react-router";
// import axios from 'axios';
import { BASE_URL } from "../../Consts/variables";
import useUniversalFetch from "../../Hooks/useApi";
// import { useRoutes } from 'react-router';

const Login = () => {
  const { useFetchMutation } = useUniversalFetch();

  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  const {
    data: handleSignInData,
    isSuccess: isSuccessSignin,
    mutate: handleSignIn,
    isLoading: isHandleSignInLoading,
    error: handleSignInError,
    isError: ishandleSignInError,
  } = useFetchMutation({
    url: `${BASE_URL}employee/login`,
    method: "POST",
  });

  console.log(BASE_URL);

  useEffect(() => {
    if (isSuccessSignin) {
      message.success(handleSignInData?.message);
      // Handle your response here. For example, save the auth token:
      localStorage.setItem(
        "authToken",
        JSON.stringify(handleSignInData?.data?.token)
      );
      localStorage.setItem("userData", JSON.stringify(handleSignInData?.data));
      setTokenExpiration();
      if (handleSignInData?.data.role === "dev") {
        navigate("/task-playgrounds");
      } else {
        navigate("/projects");
      }
    } else if (ishandleSignInError) {
      message.error(handleSignInError?.message);
      setStatus("error");
    }
  }, [ishandleSignInError, handleSignInData, handleSignInError]);

  const onFinish = async (values) => {
    try {
      handleSignIn({
        username: values.username,
        password: values.password,
      });
    } catch (error) {}
  };

  const setTokenExpiration = () => {
    const now = new Date();
    const expirationDate = new Date(now.getTime() + 3 * 24 * 60 * 60 * 1000); // 7 days from now
    localStorage.setItem("token_expiration", expirationDate.toISOString());
  };

  return (
    <div className="login-page">
      <div className="login-page-wrapper">
        <div className="leftside">
          <img className="header-logo" src={Logo} alt="Logo" />
          <img className="line" src={Line} alt="Background" />
        </div>
        <div className="rightside">
          <div className="center">
            <h1>Log In to Your Account</h1>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <div className="single-item">
                <p>
                  Username <span>*</span>
                </p>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Username!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Username"
                    status={status}
                    onChange={(e) => setStatus("")}
                  />
                </Form.Item>
                <Icon icon="user" />
              </div>
              <div className="single-item">
                <p>
                  Password <span>*</span>
                </p>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    placeholder="Password"
                    status={status}
                    onChange={(e) => setStatus("")}
                  />
                </Form.Item>
                <Icon icon="key" />
              </div>
              {/* <Form.Item>
                        <div className="remember">
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>Remember username</Checkbox>
                            </Form.Item>
                        </div>
                    </Form.Item> */}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={isHandleSignInLoading}
                >
                  Log In to Account
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
