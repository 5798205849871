import React, { useState, useEffect } from "react";
import { Input, DatePicker, Select, Button, Modal, Form } from "antd";
// import { CheckOutlined } from '@ant-design/icons';
import Icon from "../../../../../Components/Icon";
import "react-phone-number-input/style.css";
import { BASE_URL } from "../../../../../Consts/variables";
import useUniversalFetch from "../../../../../Hooks/useApi";
import moment from "moment";
import dayjs from "dayjs";

const ExpenseModal = ({
  expense,
  isVisible,
  onSave,
  onCancel,
  isEditMode,
  isLoading,
}) => {
  const [form] = Form.useForm();
  const [disableRate, setDisableRate] = useState(false);

  const token =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("authToken"));
  const { useFetchQuery, useFetchMutation, usePatchMutation } =
    useUniversalFetch();

  const {
    data: currencyData,
    isLoading: isCurrencyLoading,
    error: currencyError,
    isError: isCurrencyError,
  } = useFetchQuery({
    queryKey: "currency",
    token,
    url: `${BASE_URL}setting/currency`,
  });

  const currencies = currencyData?.status === 200 ? currencyData.data : null;

  const {
    data: expenseData,
    isLoading: isExpenseLoading,
    error: expenseError,
    isError: isExpenseError,
    isSuccess: isSuccesExpense,
  } = useFetchQuery({
    queryKey: "expense-type",
    token: token,
    url: `${BASE_URL}setting/expense-type`,
  });
  const expenseTypes = expenseData?.status === 200 ? expenseData.data : null;

  const formatNumber = (value) => {
    return value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const handleChange = (e) => {
    let val = formatNumber(e.target.value);
    form.setFieldsValue({ cost: val });
  };

  const handleChangeCurrnetRate = (e) => {
    let val = formatNumber(e.target.value);
    form.setFieldsValue({ current_rate: val });
  };

  const handleCurrencyChange = () => {
    const currencyValue = form.getFieldValue("currency");
    const finderCurrency = currencies.find(
      (item) => item.name?.toLowerCase() === currencyValue?.toLowerCase()
    );

    if (finderCurrency) {
      form.setFieldValue(
        "current_rate",
        Number(finderCurrency.rate)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      );
    }

    if (currencyValue.toLowerCase() === "uzs") {
      setDisableRate(true);
      form.setFieldValue("current_rate", "1");
    } else {
      setDisableRate(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue(
      expense
        ? {
            project_name: expense?.project_name,
            expense_types: expense?.expense_types?.[0].id,
            method: expense?.method,
            date: dayjs(
              String(moment(Number(expense?.date)).format("DD/MM/YYYY")),
              dateFormat
            ),
            comment: expense?.comment,
            cost: expense?.cost
              ? Number(expense.cost)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              : "",

            currency: expense?.currency,
            current_rate: expense?.current_rate
              ? Number(expense.current_rate)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              : "",
          }
        : {
            project_name: null,
            expense_types: null,
            method: null,
            date: null,
            comment: null,
            cost: null,
            current_rate: null,
            currency: null,
          }
    );
  }, [expense, form, isVisible]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onSave(values, isEditMode);
        // onCancel();
      })
      .catch((error) => {
        console.error("Submission failed:", error?.message);
      });
  };

  const dateFormat = "DD/MM/YYYY";

  const modalHeader = (
    <div>
      <h1>{isEditMode ? "Edit Expense" : "Add Expense"}</h1>
      <p>
        {isEditMode
          ? "Modify the details of this expence."
          : "Fill in the details for the new expence."}
      </p>
    </div>
  );

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={isVisible}
      onCancel={handleCancel}
      destroyOnClose
      className="expenses-modal"
      width={990}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={handleSubmit}
        >
          {isEditMode ? "Update" : "Save"}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <div className="form-wrapper">
          <div className="title">{modalHeader}</div>
          <div className="input-wrapper expenses-input-wrapper">
            <div className="single-input-item">
              <Form.Item
                name="project_name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please fill the input",
                    // validateTrigger: 'onChange'
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Input placeholder="Write name..." />
              </Form.Item>
            </div>

            <div className="single-input-item">
              <Form.Item
                name="expense_types"
                label="Type"
                rules={[
                  {
                    required: true,
                    message: "Select expense type...",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Select placeholder="Select type...">
                  {expenseTypes
                    ?.filter((c) => c.is_active)
                    .map((activeTitle) => (
                      <Select.Option
                        key={activeTitle.id}
                        value={activeTitle.id}
                      >
                        {activeTitle.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>

            <div className="single-input-item">
              <Form.Item
                name="method"
                label="Method"
                rules={[
                  {
                    required: true,
                    message: "Select method...",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Select placeholder="Select method...">
                  <Select.Option value="cash">Cash</Select.Option>
                  <Select.Option value="card">Card</Select.Option>
                  <Select.Option value="bank">Bank</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="single-input-item calendar">
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                    message: "Please input date!",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <DatePicker
                  placeholder="DD/MM/YYYY"
                  format={dateFormat}
                  onChange={(dateString) => {
                    form.setFieldsValue({ date: dateString });
                  }}
                  defaultValue={expense?.date ? moment(expense.date) : null}
                />
              </Form.Item>
            </div>
            <div className="single-input-item">
              <Form.Item name="comment" label="Comment">
                <Input placeholder="Write comment..." />
              </Form.Item>
            </div>
            <div className="single-input-item salary">
              <Form.Item
                label="Cost"
                name="cost"
                className="salary-inner"
                rules={[
                  {
                    required: true,
                    message: "Please fill",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Input
                  value={form.getFieldValue("cost")}
                  onChange={handleChange}
                  placeholder="Add salary..."
                  maxLength={10}
                />
              </Form.Item>
              <Form.Item
                className="currency"
                name="currency"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Select
                  placeholder="UZS"
                  style={{ width: 90 }}
                  loading={isCurrencyLoading}
                  onChange={handleCurrencyChange}
                >
                  {currencies
                    ?.filter((c) => c.is_active)
                    .map((activeCity) => (
                      <Select.Option
                        key={activeCity.id}
                        value={activeCity.name}
                      >
                        {activeCity.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className="single-input-item">
              <Form.Item
                label="Current currency rate"
                name="current_rate"
                className="salary-inner"
                rules={[
                  {
                    required: true,
                    message: "Please fill",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Input
                  value={form.getFieldValue("current_rate")}
                  onChange={handleChangeCurrnetRate}
                  placeholder="Add current rate..."
                  maxLength={10}
                  disabled={disableRate}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ExpenseModal;
