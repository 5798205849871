import React from 'react';
import { Input, DatePicker, Button, Modal, Form } from 'antd';
import Icon from "../../../../Components/Icon";
import PropTypes from 'prop-types'; 


// const { TextArea } = Input;


const ProjectAdd = ({ isVisible, onSave, onCancel , isLoading}) => {

    const [form] = Form.useForm();

    const handleCancel = () => {
        form.resetFields();
        onCancel();
    };

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            onSave(values);
        } catch (error) {
            console.error('Validation failed:');
        }
    };

    const dateFormat = 'DD/MM/YYYY';

    const onChange = (date, dateString) => {
    };

    // const options = [
    //     {
    //         label: "Designer",
    //         value: "Designer"
    //     },
    //     {
    //         label: "Mobile",
    //         value: "Mobile"
    //     },
    //     {
    //         label: "Backand",
    //         value: "Backand"
    //     },
    //     {
    //         label: "Frontend",
    //         value: "Frontend"
    //     },
    // ]

    // const handleChange = (value) => {
    //     console.log(`selected ${value}`);
    // };    

    return (
        
            <Modal
            open={isVisible}
            onCancel={handleCancel}
            destroyOnClose
            // width={770}
            className='min-modal'
            footer={[
                <Button key="back" type="primary" onClick={handleCancel} ghost>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmit} ghost>
                    Save
                </Button>
            ]}
            >
                <div className="form-wrapper">
                    <div className="title">
                        <h1>Add project</h1>
                        <p>Add your project and save</p>
                    </div>
                    <Form form={form} layout="vertical">
                        <div className="form-wrapper">
                            <div className="upper">
                                <div className="single-item calendar">
                                    <Form.Item name="startDate" label="Start date"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Select start date",
                                                
                                            },
                                          ]}
                                    >
                                        <DatePicker placeholder='DD/MM/YYYY' format={dateFormat} onChange={onChange}/>
                                    </Form.Item>
                                    <Icon icon="data"/>
                                </div>
                                <div className="single-item">
                                    <Form.Item name="projectName" label="Project name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input project name",
                                                
                                            },
                                          ]}
                                    >
                                        <Input placeholder='Project name'/>
                                    </Form.Item>
                                </div>
                                <div className="single-item calendar">
                                    <Form.Item name="Deadline" label="Deadline"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Select deadline",
                                                
                                            },
                                          ]}
                                    >
                                        <DatePicker placeholder='DD/MM/YYYY' format={dateFormat} onChange={onChange}/>
                                    </Form.Item>
                                    <Icon icon="data"/>
                                </div>
                            </div>
                        </div>
                        {/* <div className="title">
                            <h1>Add developers</h1>
                            <p>Add your developer and save</p>
                        </div>
                        <div className="form-wrapper">
                            <div className="upper full-width">
                                <div className="single-item">
                                    <Form.Item name="developer" label="Add developer">
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Please select"
                                            onChange={handleChange}
                                            options={options}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div> */}
                    </Form>
                </div>
            </Modal>
    );
};

ProjectAdd.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default ProjectAdd;
