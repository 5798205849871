import { Input, Space, Select, Button, Table, Popover, Spin } from 'antd';
import { useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import Icon from '../../../Components/Icon';
import useUniversalFetch from '../../../Hooks/useApi';
import { BASE_URL } from '../../../Consts/variables';
import moment from 'moment';

// Search input

const { Search } = Input;



// const onSearch = (value, _e, info) => console.log(info?.source, value);

// Select handle







function ProjectsLog() {

    const [opacity, setOpacity] = useState("0")
    const [loader, setLoader] = useState("1")
    const [searchValue, setSearchValue] = useState("")
    const [status, setStatus] = useState("all")
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
      });

    const token = typeof window !== "undefined" && JSON.parse(localStorage.getItem('authToken'));
    const {useFetchQuery, useFetchMutation} = useUniversalFetch()
    const {
        data: projectsLogData,
        isLoading: isProjectsLogDataLoading,
        error: projectLogDataError,
      } = useFetchQuery({
        queryKey: ['projects', pagination.current, pagination.pageSize],
        token: token,
        url: `${BASE_URL}project-log`,
      id:`?search=${searchValue}&page=${pagination.current}&page_size=${pagination.pageSize}${status !== "all" ? `&status=${status}` : ""}`
      
  });  


  const handleChange = (value) => {
    setStatus(value)
  };

  const handleTableChange = (pagination) => {
    // Update pagination state to reflect the new current page
    setPagination((prev) => ({
      ...prev,
      current: pagination.current,
      pageSize: pagination.pageSize,
    }));
  };

  useEffect(()=>{
    if(!isProjectsLogDataLoading){
      setLoader("0")
      setOpacity("1")
    }else{
      setLoader("1");
      setOpacity("0");
    }
  },[isProjectsLogDataLoading,projectsLogData])

  const projectsLog = projectsLogData?.data ? projectsLogData?.data : []


  const data = projectsLog



  // Table Columns

const columns = [
    {
        title: 'Project name',
        dataIndex: '',  
        render:(_,record)=>(
            <div>{record?.project?.name}</div>
        )
    },
    {
        title: 'Previous status',
        dataIndex: 'previous_status',
        width: 130,
        render: (previous_status) => {
            let backgroundColor;
    
            // Determine background color based on status
            switch (previous_status) {
                case 'inprogress':
                    backgroundColor = 'rgba(0, 123, 255, 0.3)';
                    break;
                case 'stoped':
                    backgroundColor = 'rgba(255, 0, 0, 0.3)';
                    break;
                case 'not started':
                    backgroundColor = 'rgba(255, 165, 0, 0.3)';
                    break;
                case 'testing':
                    backgroundColor = 'rgba(128, 0, 128, 0.3)';
                    break; 
                case 'archived':
                    backgroundColor = 'rgba(100, 100, 100, 0.3)';
                    break;
                case 'finished':
                    backgroundColor = 'rgba(0, 200, 0, 0.3)';
                    break;            
                default:
                    backgroundColor = 'transparent';
            }
    
            return (
                <span style={{ backgroundColor }} className='status'>{previous_status}</span>
            );
        },
    },
    
    {
        title: 'Changed status',
        dataIndex: 'changed_status',
        width: 130,
        render: (changed_status) => {
            let backgroundColor;
    
            // Determine background color based on status
            switch (changed_status) {
                case 'inprogress':
                    backgroundColor = 'rgba(0, 123, 255, 0.3)';
                    break;
                case 'stoped':
                    backgroundColor = 'rgba(255, 0, 0, 0.3)';
                    break;
                case 'not started':
                    backgroundColor = 'rgba(255, 165, 0, 0.3)';
                    break;
                case 'testing':
                    backgroundColor = 'rgba(128, 0, 128, 0.3)';
                    break;
                case 'archived':
                    backgroundColor = 'rgba(100, 100, 100, 0.3)';
                    break;   
                case 'finished':
                    backgroundColor = 'rgba(0, 200, 0, 0.3)';
                    break;     
                default:
                    backgroundColor = 'transparent';
            }
    
            return (
                <span style={{ backgroundColor }} className='status'>{changed_status}</span>
            );
        },
    },
    {
        title: 'Previous deadline',
        dataIndex: 'previous_deadline',
        width: 140,
        render: (previous_deadline) => (
            <span className='date'>
                <Icon icon="complete"/>
                {moment(Number(previous_deadline)).format('DD.MM.YYYY')}
            </span>
        ),
    },
    {
        title: 'Changed deadline',
        dataIndex: 'changed_deadline',
        width: 140,
        render: (changed_deadline) => (
            <span className='date'>
                <Icon icon="complete"/>
                {moment(Number(changed_deadline)).format('DD.MM.YYYY')}
            </span>
        ),
    },
    {
        title: 'Comment',
        dataIndex: 'comment',
        minWith: 250,
        render: (comment)=>{
            return(
                <span className='comment'>
                    <Popover placement="bottom" content={comment}>
                        <Button type="primary">{<p>{comment}</p>}</Button>
                        <Icon icon="drop-down"/>
                    </Popover>
                    
                </span>
            )
        }
    },
    {
        title: 'Updated by',
        dataIndex: 'updatedBy',
        render: (updatedBy)=>(
            <span className='updated-by'>{updatedBy?.fullname}</span>
        )
    },
    {
        title: 'Created at',
        dataIndex: 'createdAt',
        width: 140,
        render: (createdAt) => (
            <span className='date'>
                <Icon icon="clock"/>
                {moment(Number(createdAt)).format('DD.MM.YYYY HH:mm')}
            </span>
        ),
    },
];


    const [size] = useState('large'); // default is 'middle'
    return (
        <>
            <div className="projects_log" style={{ opacity: opacity }}>
            <div className="page-title">
                <h1>Project Logs</h1>
                <p>Here you may see information about projects</p>
            </div>
            <div className="upper-section">
                <div className="left">
                    <div className="search-btn">
                        <Space direction="vertical">
                            <Search
                                placeholder="Search for projects..."
                                onChange={(value) => setSearchValue(value.target.value)}
                                style={{
                                    width: 200,
                                }}
                            />
                        </Space>
                    </div>
                    <div className="select">
                        <Space wrap>
                            <Select
                                placeholder="Status"
                                style={{
                                    width: 170,
                                }}
                                onChange={handleChange}
                                options={[
                                    {
                                        value: 'all',
                                        label: 'All',
                                    },
                                    {
                                        value: 'not started',
                                        label: 'Not started',
                                    },
                                    {
                                        value: 'inprogress',
                                        label: 'In progress',
                                    },
                                    {
                                        value: 'testing',
                                        label: 'Testing',
                                    },
                                    {
                                        value: 'stoped',
                                        label: 'Stopped',
                                    },
                                    {
                                        value: 'finished',
                                        label: 'Finished',
                                    },
                                    {
                                        value: 'archived',
                                        label: 'Archived',
                                    },
                                ]}
                            />
                        </Space>
                        <Icon icon="arrow-down"/>
                    </div>
                </div>
                {/* <div className="download-btn">
                    <Button type="default" shape="round" size={size}>
                        Download
                        <DownloadOutlined />
                    </Button>
                </div> */}
            </div>
            <div className="table">
                 <Table
                    columns={columns}
                    dataSource={projectsLogData?.data || []} // Assuming the data property holds the array
                    loading={isProjectsLogDataLoading}
                    pagination={{
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: projectsLogData?.total_elements || 0, // Total records from the backend
                        showSizeChanger: true,
                    }}
                    onChange={handleTableChange} // Calls this function whenever page or size changes
                />

                {/* <span className='item-amout'>Showing 1 to 6 of 50 entries</span> */}
            </div>
        </div>
        <div className="loader" style={{ opacity: loader }}>
            <Spin
            indicator={
                <LoadingOutlined
                style={{
                    fontSize: 44,
                }}
                spin
                />
            }
            />
        </div>
        </>
    );
}

export default ProjectsLog;
