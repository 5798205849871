import {
  Button,
  Popover,
  Form,
  Input,
  Select,
  notification,
  Spin,
  Popconfirm,
} from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import Icon from "../../../Components/Icon";
import { Link } from "react-router-dom";
import CustomModal from "../Sales/calculator/resultModal";
import { useState, useEffect } from "react";
import { BASE_URL } from "../../../Consts/variables";
import useUniversalFetch from "../../../Hooks/useApi";
import moment from "moment";

function Playground() {
  const colors = [
    {
      id: 1,
      color: "#1953E9",
      name: "Blue",
    },
    {
      id: 2,
      color: "#FFC700",
      name: "Yellow",
    },
    {
      id: 3,
      color: "#1A8400",
      name: "Green",
    },
    {
      id: 4,
      color: "#F76707",
      name: "Orange",
    },
    {
      id: 5,
      color: "#F81515",
      name: "Red",
    },
    {
      id: 6,
      color: "#D64CED",
      name: "Purple",
    },
  ];

  const userData = JSON.parse(localStorage.getItem("userData"));

  let role = userData?.role;
  const [modalVisible, setModalVisible] = useState(false);
  const [opacity, setOpacity] = useState("0");
  const [loader, setLoader] = useState("1");
  const [modalTitle, setModalTitle] = useState("Add playground");
  const [isEdit, setIsEdit] = useState(false);
  const [activePlayground, setActivePlayground] = useState();
  const [canban_status, setCanban_status] = useState(colors[0]);

  const [form] = Form.useForm();

  const token =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("authToken"));
  const {
    useFetchQuery,
    useFetchMutation,
    usePatchMutation,
    useDeleteMutation,
  } = useUniversalFetch();
  const {
    data: playgroundData,
    isLoading: isPlaygroundDataLoading,
    error: playgroundDataError,
    isError: isPlaygroundDataError,
  } = useFetchQuery({
    queryKey: "playground",
    token: token,
    url: `${BASE_URL}playground`,
  });

  const playground = playgroundData?.data ? playgroundData?.data : [];

  useEffect(() => {
    if (!isPlaygroundDataLoading) {
      setLoader("0");
      setOpacity("1");
    } else {
      setLoader("1");
      setOpacity("0");
    }
  }, [isPlaygroundDataLoading, playgroundData]);

  const {
    data: playgroundCreateData,
    isSuccess: isSuccessCreated,
    mutate: PlaygroundCreate,
    isLoading: isPlaygroundCreateLoading,
    error: playgroundCreateError,
    isError: isPlaygroundCreateError,
  } = useFetchMutation({
    url: `${BASE_URL}playground`,
    method: "POST",
    token: token,
  });

  const {
    data: employeeData,
    isLoading: isEmployeeDataLoading,
    error: employeeDataError,
    isError: isEmployeeDataError,
    isSuccess: isSuccesEmployeeData,
  } = useFetchQuery({
    queryKey: "employee",
    token: token,
    url: `${BASE_URL}employee/find-by-role`,
    // id: `?role=${"dev"}`,
  });

  const employees = employeeData?.data ? employeeData?.data : [];

  const {
    data: playgroundDeleteData,
    isSuccess: isSuccessDelete,
    mutate: playgroundDeleteMutation,
    isLoading: isPlaygroundDeleteLoading,
    error: playgroundDeleteError,
    isError: isPlaygroundDeleteError,
  } = useDeleteMutation({
    url: `${BASE_URL}playground`,
    method: "DELETE",
    token: token,
  });

  const {
    data: playgroundUpdateData,
    isSuccess: isSuccessUpdated,
    mutate: PlaygroundUpdate,
    isLoading: isPlaygroundUpdateLoading,
    error: playgroundUpdateError,
    isError: isPlaygroundUpdateError,
  } = usePatchMutation({
    url: `${BASE_URL}playground`,
    method: "PATCH",
    token: token,
  });

  useEffect(() => {
    if (isSuccessCreated) {
      notification.success({
        // message: 'Project created',
        message: playgroundCreateData?.message,
      });
      setModalVisible(false);
      form.resetFields();
      setCanban_status(colors[0]);
    } else if (isPlaygroundCreateError) {
      notification.error({
        message: "Playground not created",
        description: playgroundCreateError?.message,
      });
    }
  }, [isPlaygroundCreateError, playgroundCreateData, playgroundCreateError]);

  useEffect(() => {
    if (isSuccessUpdated) {
      notification.success({
        // message: 'Project created',
        message: playgroundUpdateData?.message,
      });
      setModalVisible(false);
      form.resetFields();
      setCanban_status(colors[0]);
    } else if (isPlaygroundUpdateError) {
      notification.error({
        message: "Playground not updated",
        description: playgroundUpdateError?.message,
      });
    }
  }, [isPlaygroundUpdateError, playgroundUpdateData, playgroundUpdateError]);

  const handleOpenModal = () => {
    form.resetFields();
    setModalTitle("Add playground");
    setIsEdit(false);
    setModalVisible(true);
  };

  const handleCloseModalConfirm = () => {
    setModalVisible(false);
  };

  const handleSubmit = () => {
    const selectedCreators = form.getFieldValue("creators");
    const employees = selectedCreators.map((id) => ({ id }));
    if (!isEdit) {
      PlaygroundCreate({
        name: form.getFieldValue("project_name"),
        employees: employees,
        color: canban_status.color,
      });
    } else {
      PlaygroundUpdate({
        id: activePlayground,
        data: {
          name: form.getFieldValue("project_name"),
          employees: employees,
          color: canban_status.color,
        },
      });
    }
  };

  const handleOkClear = () => {
    form
      .validateFields()
      .then(() => {
        handleSubmit();
        // onCancel();
      })
      .catch((error) => {
        console.error("Submission failed:", error?.message);
      });
  };

  const handleOpenModalEdit = (item) => {
    setModalTitle("Edit playground");
    setCanban_status(
      colors?.find((color) => color?.color === item?.color) || colors[0]
    );
    setIsEdit(true);
    setModalVisible(true);
    setActivePlayground(item?.id);

    form.setFieldValue("project_name", item?.name);
    const getEmployeeIds = (employees) => {
      return employees.map((employee) => employee.id);
    };

    const employeeIds = getEmployeeIds(item?.employees);

    form.setFieldValue("creators", employeeIds);
  };

  const confirm = (id) => {
    playgroundDeleteMutation({
      id: id,
    });
  };
  const cancel = (e) => {};

  useEffect(() => {
    if (isSuccessDelete) {
      notification.success({
        // message: 'Project created',
        message: playgroundDeleteData?.message,
      });
    } else if (isPlaygroundDeleteError) {
      notification.error({
        message: "Playground not deleted",
        description: playgroundDeleteError?.message,
      });
    }
  }, [isPlaygroundDeleteError, playgroundDeleteData, playgroundDeleteError]);

  const disablepermit =
    userData?.role === "ceo" || userData?.role === "pm" ? true : false;

  return (
    <>
      <div className="task_playground" style={{ opacity: opacity }}>
        <div className="page-heading">
          <div className="page-title">
            <h1>Task playground</h1>
            <p>List of playgrounds</p>
          </div>
          <div className="upper-section">
            {disablepermit && (
              <Button
                size="large"
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleOpenModal}
              >
                Add playground
              </Button>
            )}
          </div>
          <CustomModal
            visible={modalVisible}
            onCancel={handleCloseModalConfirm}
            onOk={handleOkClear}
            recalculate={handleCloseModalConfirm}
            footerBtn={"Cencel"}
            heading={<h1>{modalTitle}</h1>}
            titleClass={"title min-title"}
          >
            <div className="task_create_modal_wrapper">
              <Form form={form} layout="vertical">
                <div className="single-input-item">
                  <Form.Item
                    name="project_name"
                    label="Project name"
                    rules={[
                      {
                        required: true,
                        message: "Please fill the input",
                        // validateTrigger: 'onChange'
                      },
                    ]}
                    validateTrigger={["onBlur", "onSubmit", "onChange"]}
                  >
                    <Input placeholder="Write name..." />
                  </Form.Item>
                </div>
                <br />
                <div className=" multiple_select_trello">
                  <Form.Item name="creators" label="Add creators">
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Please select"
                      filterOption={(input, option) => {
                        const member = employees?.find(
                          (m) => m.id === option.value
                        );
                        const fullNameMatch = member?.fullname
                          ?.toLowerCase()
                          .includes(input.toLowerCase());
                        const jobTitleMatch = member?.job_title?.name
                          ?.toLowerCase()
                          .includes(input.toLowerCase());
                        return fullNameMatch || jobTitleMatch;
                      }}
                    >
                      {employees?.map((member, index) => (
                        <Select.Option key={index} value={member.id}>
                          {member.fullname}{" "}
                          {member.job_title ? (
                            <strong>({member.job_title.name})</strong>
                          ) : null}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Form>
              <span className="color_label">Color</span>
              <div className="colors_for_playground">
                {colors &&
                  colors?.map((item) => (
                    <span
                      onClick={() => {
                        setCanban_status(item);
                      }}
                      style={
                        canban_status.id === item.id
                          ? {
                              border: `1px solid ${item.color}`,
                              color: "#ffffff",
                              backgroundColor: `${item.color}`,
                            }
                          : {
                              border: `1px solid ${item.color}`,
                              color: `${item.color}`,
                            }
                      }
                    >
                      {item.name}
                    </span>
                  ))}
              </div>
            </div>
          </CustomModal>
        </div>
        <ul className="playground_list">
          {playground &&
            playground.map((item) => (
              <li
                style={{ border: `1px solid ${item?.color}` }}
                key={item.id}
                className="list_item"
              >
                <div className="up_side">
                  <Link to={`/task-playgrounds/${item.id}`}>
                    <h2 style={{ color: `${item?.color}` }} className="title">
                      {item?.name}
                    </h2>
                  </Link>
                  {disablepermit || userData?.is_lead === true ? (
                    <div className="icon_wrapper">
                      <Popover
                        placement="bottomRight"
                        width
                        content={
                          <div className="popover_content">
                            <span onClick={() => handleOpenModalEdit(item)}>
                              <Icon icon="Frame" />
                              <p>Edit</p>
                            </span>
                            <span>
                              <Popconfirm
                                title="Delete the playground"
                                description="Are you sure to delete this playground?"
                                onConfirm={() => confirm(item?.id)}
                                onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                                okType="danger"
                              >
                                <div className="delete">
                                  <span>
                                    <Icon icon="trash" />
                                    <p>Delete</p>
                                  </span>
                                </div>
                              </Popconfirm>
                            </span>
                          </div>
                        }
                      >
                        <Icon icon="dots" />
                      </Popover>
                    </div>
                  ) : null}
                </div>
                <div className="low_side">
                  <div className="author">
                    <span>Creator:</span>
                    <p>{item?.created_by?.fullname}</p>
                  </div>
                  <div className="date">
                    {moment(Number(item.created_at)).format("DD.MM.YYYY")}
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <div className="loader" style={{ opacity: loader }}>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 44,
              }}
              spin
            />
          }
        />
      </div>
    </>
  );
}

export default Playground;
