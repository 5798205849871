import React, { useEffect } from "react";
import { useRef } from "react";
import ReactApexChart from "react-apexcharts";

const PieChart = ({ data, name }) => {
  const chartRef = useRef(null);
  const seriesData = Object.keys(data)
    .filter((key) => key !== "total")
    .map((key) => data[key]);
  const series = Object.keys(data)
    .filter((key) => key !== "total")
    .map((key) => (data[key] > 0 ? data[key] : 0));
  const labels = Object.keys(data).filter((key) => key !== "total");

  const chartColors = ["#32B4FD", "#F25555", "#79B764"];

  const options = {
    labels: labels,
    colors: chartColors,
    title: {
      text: name,
      align: "center",
      style: {
        fontSize: "24px",
        fontWeight: "500",
        color: "#000000",
        fontFamily: "Inter, sans-serif",
      },
    },
    legend: {
      position: "bottom",
      labels: {
        colors: "#263238", // default color
        useSeriesColors: false,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
              label: "Total",
              formatter: () =>
                data?.total ? data.total.toLocaleString() : "0",
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const updateTotal = (newTotal) => {
    if (chartRef.current) {
      chartRef.current.chart.updateOptions({
        plotOptions: {
          pie: {
            donut: {
              labels: {
                total: {
                  formatter: () => newTotal.toLocaleString() || "0",
                },
              },
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    updateTotal(data?.total);
  }, [data?.total]);

  return (
    <div className="chart-area">
      <div className="chart-box">
        <ReactApexChart
          ref={chartRef}
          options={options}
          series={series}
          type="donut"
          width={400}
        />
      </div>
      <ul className="label-lists">
        {seriesData &&
          seriesData?.map((el, index) => (
            <li key={index}>
              <b>{labels[index]}</b>:{" "}
              {el?.toLocaleString()?.split(",")?.join(" ")}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default PieChart;
