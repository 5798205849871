import React, { useState, useEffect } from "react";
import { Input, Space, notification, Spin, Select, Button } from "antd";

import ProjectAdd from "./Action/ProjectAdd";
import ProjectUpdateModal from "./Action/ProjectUpdate";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Icon from "../../../Components/Icon";
import { Link } from "react-router-dom";
import useUniversalFetch from "../../../Hooks/useApi";
import { BASE_URL } from "../../../Consts/variables";
import moment from "moment";

const { Search } = Input;
const Projects = () => {
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("all");
  const [textColors] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeProject, setActiveProject] = useState(null);
  const [opacity, setOpacity] = useState("0");
  const [loader, setLoader] = useState("1");
  const [disable, setDisable] = useState(true);

  const token =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("authToken"));
  const { useFetchQuery, useFetchMutation } = useUniversalFetch();
  const {
    data: projectsData,
    isLoading: isProjectsDataLoading,
    error: projectsDataError,
    isError: isProjectsDataError,
  } = useFetchQuery({
    queryKey: "projects",
    token: token,
    url: `${BASE_URL}project`,
    id: `?name=${searchValue}${
      statusValue !== "all" ? `&status=${statusValue}` : ""
    }`,
  });

  const projects = projectsData?.data ? projectsData?.data : [];

  useEffect(() => {
    if (!isProjectsDataLoading) {
      setLoader("0");
      setOpacity("1");
    } else {
      setLoader("1");
      setOpacity("0");
    }
  }, [isProjectsDataLoading, projectsData]);

  const {
    data: projectCreateData,
    isSuccess: isSuccessCreated,
    mutate: ProjectCreate,
    isLoading: isProjectCreateLoading,
    error: projectCreateError,
    isError: isProjectCreateError,
  } = useFetchMutation({
    url: `${BASE_URL}project`,
    method: "POST",
    token: token,
  });

  const {
    data: projectUpdateData,
    isSuccess: isSuccessUpdated,
    mutate: ProjectUpdate,
    isLoading: isProjectUpdateLoading,
    error: projectUpdateError,
    isError: isProjectUpdateError,
  } = useFetchMutation({
    url: `${BASE_URL}project/quick-update/${activeProject?.id}`,
    method: "PATCH",
    token: token,
  });

  const colorRanges = [
    {
      min: 0,
      max: 6,
      status: "finished",
      trackColor: "rgba(125, 237, 84, 1)",
      bgColor: "rgba(125, 237, 84, 0.3)",
    },
    {
      min: 6,
      max: 12,
      trackColor: "rgb(0, 247, 0)",
      bgColor: "rgba(0, 247, 0, 0.3)",
    },
    {
      min: 12,
      max: 20,
      trackColor: "rgb(19, 247, 1)",
      bgColor: "rgba(19, 247, 1, 0.3)",
    },
    {
      min: 20,
      max: 26,
      trackColor: "rgb(111, 237, 80)",
      bgColor: "rgba(111, 237, 80, 0.3)",
    },
    {
      min: 26,
      max: 32,
      trackColor: "rgb(0, 255, 0)",
      bgColor: "rgba(0, 255, 0, 0.3)",
    },
    {
      min: 32,
      max: 38,
      trackColor: "rgb(51, 255, 0)",
      bgColor: "rgba(51, 255, 0, 0.3)",
    },
    {
      min: 38,
      max: 44,
      trackColor: "rgb(102, 255, 0)",
      bgColor: "rgba(102, 255, 0, 0.3)",
    },
    {
      min: 44,
      max: 50,
      trackColor: "rgb(255, 255, 0)",
      bgColor: "rgba(255, 255, 0, 0.3)",
    },
    {
      min: 50,
      max: 56,
      status: "not started",
      trackColor: "rgb(255, 204, 0)",
      bgColor: "rgba(255, 204, 0, 0.3)",
    },
    {
      min: 56,
      max: 62,
      status: "stoped",
      trackColor: "rgb(255, 153, 0)",
      bgColor: "rgba(255, 153, 0, 0.3)",
    },
    {
      min: 62,
      max: 70,
      trackColor: "rgb(255, 133, 52)",
      bgColor: "rgba(255, 133, 52, 0.3)",
    },
    {
      min: 70,
      max: 76,
      trackColor: "rgb(255, 102, 0)",
      bgColor: "rgba(255, 102, 0, 0.3)",
    },
    {
      min: 76,
      max: 82,
      trackColor: "rgb(253, 92, 37)",
      bgColor: "rgba(253, 92, 37, 0.3)",
    },
    {
      min: 82,
      max: 88,
      trackColor: "rgb(253, 92, 37)",
      bgColor: "rgba(253, 92, 37, 0.3)",
    },
    {
      min: 88,
      max: 94,
      trackColor: "rgb(253, 31, 30)",
      bgColor: "rgba(253, 31, 30, 0.3)",
    },
    {
      min: 94,
      max: 100,
      trackColor: "rgb(250, 0, 28)",
      bgColor: "rgba(250, 0, 28, 0.3)",
    },
  ];

  function getColorForProgress(progress, status) {
    const range = colorRanges.find(
      (range) => progress >= range.min && progress <= range.max
    );

    const range2 =
      status && colorRanges.find((rangeItem) => rangeItem.status === status);

    return range2
      ? range2.trackColor
      : range
      ? range.trackColor
      : "rgb(255,255,255)";
  }

  function getBgForProgress(progress) {
    const range = colorRanges.find(
      (range) => progress >= range.min && progress <= range.max
    );
    return range ? range.bgColor : "rgb(255,255,255)";
  }

  useEffect(() => {
    if (isSuccessCreated) {
      notification.success({
        // message: 'Project created',
        message: projectCreateData?.message,
      });
      setIsModalVisible(false);
    } else if (isProjectCreateError) {
      notification.error({
        message: "Project not created",
        description: projectCreateError?.message,
      });
    }
  }, [isProjectCreateError, projectCreateData, projectCreateError]);

  const handleSaveNew = (values) => {
    ProjectCreate({
      start: new Date(values.startDate).getTime(),
      deadline: new Date(values.Deadline).getTime(),
      name: values.projectName,
    });
  };

  const handleUpdate = (values) => {
    ProjectUpdate({
      deadline: values.Deadline
        ? new Date(values.Deadline).getTime()
        : Number(activeProject?.deadline),
      // "name": values.changeName,
      name: values?.projectName ? values.projectName : "",
      status: values.changeStatus ? values.changeStatus : activeProject?.status,
      comment: values.comments,
      start: Number(activeProject.start),
    });
    // Close modal after update
  };

  useEffect(() => {
    if (isSuccessUpdated) {
      notification.success({
        // message: 'Project created',
        message: projectUpdateData?.message,
      });
      setActiveProject(null);
    } else if (isProjectUpdateError) {
      notification.error({
        message: "Project not updated",
        description: projectUpdateError?.message,
      });
    }
  }, [isProjectUpdateError, projectUpdateData, projectUpdateError]);

  const handleCancelNew = () => {
    setIsModalVisible(false);
  };

  const handleCancelUpdate = () => {
    setActiveProject(null);
  };

  const trackedStatuses = ["finished", "stoped", "not started"];

  // const getColorByStatusAndTime = (status, passedTime) => {
  //   if (status === 'finished') {
  //     return { primaryColor: 'rgba(125, 237, 84, 1)', secondaryColor: 'rgba(125, 237, 84, 0.3)' };  // Assuming 'green' is the color code you want
  //   } else if (status === 'stoped') {
  //     return { primaryColor: 'rgba(255, 133, 52, 1)', secondaryColor: 'rgba(255, 133, 52, 0.3)' };  // Assuming 'red' is the color code you want
  //   } else {
  //     const index = Math.floor((passedTime / 100) * (colorPairs.length - 1));
  //     return colorPairs[Math.max(0, Math.min(index, colorPairs.length - 1))];
  //   }
  // };

  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (userData?.role === "pm" || userData?.role === "ceo") {
      setDisable(false);
    }
  }, [userData]);

  return (
    <>
      <div className="projects" style={{ opacity: opacity }}>
        <div className="upper-section">
          <div className="upper_filter">
            <div className="search-btn">
              <Space direction="">
                <Search
                  placeholder="Search for projects..."
                  onChange={(value) => setSearchValue(value.target.value)}
                  style={{
                    width: 200,
                  }}
                />
              </Space>
            </div>
            <Select
              placeholder="Select status..."
              className="status_search"
              onChange={(value) => setStatusValue(value)}
            >
              <Select.Option value="all">All</Select.Option>
              <Select.Option value="not started">Not started</Select.Option>
              <Select.Option value="inprogress">In progress</Select.Option>
              <Select.Option value="testing">Testing</Select.Option>
              <Select.Option value="stoped">Stopped</Select.Option>
              <Select.Option value="finished">Finished</Select.Option>
              <Select.Option value="archived">Archived</Select.Option>
            </Select>
          </div>
          {!disable && (
            <div className="download-btn">
              <Button
                size="large"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setIsModalVisible(true)}
              >
                Add project
              </Button>
            </div>
          )}
        </div>
        <div className="projects-list">
          <ul>
            {Array.isArray(projects) &&
              projects.map((project) => {
                const rangeColor = getColorForProgress(
                  project?.passed_time,
                  project?.status
                );
                const rangeBgColor = getBgForProgress(project?.passed_time);
                // getColorByStatusAndTime(project?.status, project?.passed_time);
                // const textColor = textColors[project?.id] || '#5F5F5F';

                return (
                  <li key={project.id}>
                    <div className="item">
                      <div className="project-title">
                        <Link projecte={project} to={`/projects/${project.id}`}>
                          <h2>{project.name}</h2>
                        </Link>
                        {!disable && (
                          <Icon
                            icon="Frame"
                            onClick={() => setActiveProject(project)}
                          />
                        )}
                      </div>
                      <div className="project-info">
                        <div className="info">
                          <div className="start-date">
                            <Icon icon="start" />
                            <span>
                              {moment(Number(project.start)).format(
                                "DD.MM.YYYY"
                              )}
                            </span>
                          </div>
                          <div className="status">
                            <span>{project?.status}</span>
                          </div>
                          <div className="completion-date">
                            <Icon icon="complete" />
                            <span>
                              {moment(Number(project.deadline)).format(
                                "DD.MM.YYYY"
                              )}
                            </span>
                          </div>
                        </div>
                        <div
                          className="proccess"
                          style={{ backgroundColor: rangeBgColor }}
                        >
                          <div
                            className="proccess-line"
                            style={{
                              backgroundColor: rangeColor,
                              width: ` ${
                                trackedStatuses.includes(project.status)
                                  ? 100
                                  : project?.passed_time || 0
                              }%`,
                            }}
                          ></div>
                          <span
                            style={{
                              color:
                                project?.passed_time > 55 ? "#fff" : "#5F5F5F",
                            }}
                          >
                            {project.passed_time}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        {isModalVisible && (
          <ProjectAdd
            isVisible={isModalVisible}
            onSave={handleSaveNew}
            onCancel={handleCancelNew}
            isLoading={isProjectCreateLoading}
          />
        )}
        {activeProject && (
          <ProjectUpdateModal
            isVisible={!!activeProject}
            projectData={activeProject}
            isLoading={isProjectUpdateLoading}
            onSave={handleUpdate}
            onCancel={handleCancelUpdate}
          />
        )}
      </div>
      <div className="loader" style={{ opacity: loader }}>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 44,
              }}
              spin
            />
          }
        />
      </div>
    </>
  );
};

export default Projects;
